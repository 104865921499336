// useSockette.js
import { useState, useEffect, useRef, useCallback } from 'react';
import Sockette from 'sockette';

const useSockette = (url) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [socket, setSocket] = useState(null);
  const socketRef = useRef(null);

  useEffect(() => {
    const sockette = new Sockette(url, {
      timeout: 5000,
      maxAttempts: 10, 
      onopen: () => {
        setIsConnected(true);
        setError(null);
      },
      onmessage: (event) => {
        setData(JSON.parse(event.data));
      },
      onreconnect: () => {
        setIsConnected(true);
        setError(null);
      },
      onerror: (event) => {
        console.error('WebSocket error:', event);
        setError(event);
      },
      onclose: () => {
        setIsConnected(false);
      }
    });

    setSocket(sockette);
    socketRef.current = sockette;

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, [url]);

  const sendMessage = useCallback((message) => {
    if (socketRef.current && isConnected) {
      socketRef.current.send(JSON.stringify(message));
    } else {
      console.error('WebSocket is not connected');
    }
  }, [isConnected]);

  return { data, error, isConnected, sendMessage };
};

export default useSockette;
