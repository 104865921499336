import React, { useState } from "react";
import Label from "../Label";
import { classNames } from "utils";
import { isBoolean } from "utils/helpers";
import TextArea from "../TextArea";
import Input from "../Input";
import ImageUpload from "../ImageUpload";

const SelectWithConditional = ({
    name,
    label,
    register,
    error,
    value,
    selectOptions,
    options = {},
    ...rest
}) => {
    const [selectedOption, setSelectedOption] = useState(value || "");
    const hasError = isBoolean(error) ? error : !!error;
    const handleSelectChange = (e) => {
        setSelectedOption(e.target.value);
    };

    let { conditional } = rest;

    return (
        <div className="flex flex-col mt-4">
            <div className="flex items-center">
                <label
                    htmlFor={`${name}-select`}
                    className={classNames(
                        "block text-sm",
                        options.readOnly && "opacity-60 cursor-not-allowed"
                    )}
                >
                    {label && <Label name={name} label={label} error={hasError} options={options} />}
                    
                    <select
                        name={name}
                        onInput={handleSelectChange}
                        disabled={options.readOnly}
                        {...register(name, options)}
                        {...rest}
                        className={classNames(
                            hasError
                              ? "border-red-500 dark:border-red-600"
                              : "border-gray-300 dark:border-gray-300/30",
                            "mt-1 block w-full rounded-md bg-background-primary py-2 pl-3 pr-10 text-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 disabled:cursor-not-allowed disabled:border-slate-200 disabled:bg-slate-50 disabled:text-slate-500 disabled:shadow-none dark:bg-background-primary-dark sm:text-base",
                        )}
                    >
                        <option value="Not Selected">Select an option</option>
                        {selectOptions.length && selectOptions.map((opt) => (
                            <option key={opt.value} value={opt.value}>
                                {opt.label}
                            </option>
                        ))}
                    </select>
                </label>
            </div>

            {conditional && conditional[selectedOption] && (
                <div className="mt-4">
                    {conditional[selectedOption].map((input, idx) => {
                        if (input?.type === 'textarea') {
                            return (
                                <div className="mt-4" key={idx}>
                                    <TextArea
                                        name={`${name}-${input?.type}`}
                                        type={input?.type}
                                        label={input?.label}
                                        placeholder={input?.options?.placeholder}
                                        register={register}
                                        options={input?.options}
                                        value={input?.value}
                                        rows={5}
                                    />
                                </div>
                            );
                        }

                        if (input?.type === 'text') {
                            return (
                                <div className="mt-4" key={idx}>
                                    <Input
                                        name={`${name}-${input?.type}`}
                                        type={input?.type}
                                        placeholder={input?.options?.placeholder}
                                        label={input?.label}
                                        register={register}
                                        options={input?.options}
                                        value={input?.value}
                                    />
                                </div>
                            );
                        }

                        if (input?.type === 'image') {
                            return (
                                <div className="mt-4" key={idx}>
                                    <ImageUpload
                                        name={`${name}-${input?.type}`}
                                        type={input?.type}
                                        placeholder={input?.options?.placeholder}
                                        label={input?.label}
                                        register={register}
                                        options={input?.options}
                                        value={input?.value}
                                    />
                                </div>
                            );
                        }

                        return null;
                    })}
                </div>
            )}
        </div>
    );
};

export default SelectWithConditional;
