import React, {useEffect} from "react";


function EndStep() {

    useEffect(() => {
     return () => {
        localStorage.clear()
     }   
    }, [])
    
    return (
        <>
            <div className="min-h-screen shadow">
                <div className="flex flex-col items-center justify-center p-4">
                    <div className="grid w-full max-w-md gap-4 bg-white p-6 sm:grid-cols-1 md:grid-cols-1">
                        <h1 className="text-1xl p-1 font-bold">Submitted successfully. Thank you</h1>
                    </div>

                </div>

            </div>
        </>
    );
}

export default EndStep;
