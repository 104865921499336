import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Button, Modal } from "components/common";
import { useAuthState } from "contexts/auth";
import { classNames } from "utils";
import { useForm } from "react-hook-form";
import { renderEditComponent, renderViewControl } from "utils/formHelpers";
import * as loaders from "utils/loaders";
import { toast } from "react-toastify";
import Spinner from 'components/common/Spinner';
import { ImageViewer } from "react-image-viewer-dv"
import { getSubmittedFormPm, savePmForm, getSurveyFormsPm, createSurveyTicket } from "api";
import useSockette from "hooks/useNewWebSocket";
import { XMarkIcon } from "@heroicons/react/24/solid";

const statusOptions = [{value: "Completed", label: "Survey Completed"}, {value: "PM Review", label: "PM Review"},  {value: "PM Approved", label: "PM Approved"}, {value: "Ticket Created", label: "Ticket Created"}];

function ConfirmModal({ isOpen, onClose, onConfirm }) {
    if (!isOpen) return null;
    return (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
            <div className="relative bg-white rounded-lg shadow-lg p-6 max-w-sm w-full">
                <button
                    onClick={onClose}
                    className="absolute top-2 left-2 text-gray-400 hover:text-gray-600 dark:hover:text-gray-200"
                >
                    <XMarkIcon className="h-6 w-6" />
                </button>
                <p className="text-gray-700">
                    You are about to push updated information to a ConnectWise Cut-over and Test ticket that has already been created. Are you sure you want to update that ticket?
                </p>
                <button
                    onClick={onConfirm}
                    className="mt-4 bg-[#3b5aa9] text-white px-4 py-2 rounded-lg hover:bg-[#2a4579] focus:outline-none focus:ring-2 focus:ring-[#3b5aa9]"
                >
                    Ok
                </button>
            </div>
        </div>
    );
}

const EditFormModal = ({ surveyInstanceId }) => {
    const navigate = useNavigate();
    const { userHasAccess } = useAuthState();

    let { data: socketData, error: socketError, isConnected, sendMessage } = useSockette(`${process.env.REACT_APP_BACKEND_BASE_URL}/v3/real-time?ticket=${surveyInstanceId}`);

    const [form, setForm] = useState(null);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [activeCardTab, setActiveCardTab] = useState(0); 
    const [activeLineTab, setActiveLineTab] = useState(0);
    const [mainTab, setMainTab] = useState(0);
    const [dynamicOptions, setDynamicOptions] = useState({});
    const [formData, setFormData] = useState(null);
    const [preFilledData, setPreFilledData] = useState({});
    const [status, setStatus] = useState({});
    const [showModal, setShowModal] = useState(false);

    async function getSubmittedForm(instanceId) {
        try {
            setLoading(true)
            const response = await getSubmittedFormPm(instanceId)
            setData(response?.data?.data);
            setPreFilledData(response?.data?.formPrefillData);
            setStatus(response?.data?.data[0]?.status)
            setLoading(false)
        } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
            setLoading(false)
        }
    }

    async function getForm() {
        try {
            setLoading(true)
            const response = await getSurveyFormsPm();
            setForm(response?.data[0]?.form_schema);
            setLoading(false)
        } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
            setLoading(false)
        }
    }

    async function submitForm(formData) {
        try {
            setLoading(true)
            const { data } = await savePmForm(surveyInstanceId, {...formData, status})

            if (data?.message !== 'Form submitted' && data?.message !== 'Submission Updated') {
                setLoading(false)
                toast.error(`Unable to save data`)
                setShowModal(false)
            }

            const res = await createSurveyTicket(surveyInstanceId)
            
            if(res?.data?.message !== 'Ticket Created'){
                setLoading(false)
                toast.error(`Unable to Update ticket`)
                setShowModal(false)
            }


            setLoading(false)
            toast.success('Data Saved')
            setShowModal(false)
        } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
            setLoading(false)
            toast.error(`Unable to save data`)
            setShowModal(false)
        }
    }

    const {
        control,
        register,
        reset,
        trigger,
        getValues,
        watch,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        getSubmittedForm(surveyInstanceId);
        getForm();
    }, [surveyInstanceId]);

    useEffect(() => {
        getSubmittedForm(surveyInstanceId);
        getForm();
    }, [socketData])

    useEffect(() => {
        reset(preFilledData)
    }, [data])

    useEffect(() => {
      setActiveLineTab(0)
    }, [activeCardTab])

    const onSave = async () => {
        console.log(status)
        if(status === "Ticket Created"){
            setShowModal(true)
            return 
        }
        setLoading(true)
        const values = getValues();
        savePmForm(surveyInstanceId, {...values, status})
        setLoading(false)
    };
    const renderFieldValue = (field, value) => {
      switch (field.type) {
          case 'toggle':
              return <p className="text-gray-700">{value ? 'Yes' : 'No'}</p>;
          case 'text':
              return <p className="text-gray-700">{value || 'N/A'}</p>;
          case 'tel':
              return <p className="text-gray-700">{value || 'N/A'}</p>;
          case 'textArea':
              return <p className="text-gray-700">{value || 'N/A'}</p>;
          case 'image':
              return (
                  <div className="mt-2 flex justify-center">
                      {value ? (
                          <ImageViewer>
                              <img className='w-64 h-64' src={value}  alt="" />
                          </ImageViewer>
                      ) : (
                          <p className="text-gray-700">No image uploaded</p>
                      )}
                  </div>
              );
          case 'radio':
              return <p className="text-gray-700">{value || 'N/A'}</p>;
          case 'select':
              return <p className="text-gray-700">{field.selectOptions.find(opt => opt.value === value)?.label || 'N/A'}</p>;
          case 'number':
              return <p className="text-gray-700">{value || 'N/A'}</p>;
          default:
              return <p className="text-gray-700">No data available</p>;
      }
  };

    useEffect(() => {
        if (form) {
            const selects = Object.entries(form)
                .filter(([_, value]) => value.type === "select" && value.loader)
                .map(([key, value]) => ({ ...value, key }));

            selects.forEach((x) => {
                const isAsync = loaders[x.loader].constructor.name === "AsyncFunction";
                if (isAsync) {
                    loaders[x.loader]().then((response) => {
                        setDynamicOptions((prevState) => ({
                            ...prevState,
                            [x.key]: response,
                        }));
                    });
                } else {
                    const response = loaders[x.loader]();
                    setDynamicOptions((prevState) => ({
                        ...prevState,
                        [x.key]: response,
                    }));
                }
            });
            reset(formData);
        }
    }, [form]);

    if (loading) {
        return <Spinner />
    }

    return (
            <div className="min-h-[100vh] shadow font-poppins">
                {mainTab === 1 && (
                    <div className="sticky top-0 z-30 bg-white px-2 py-2">
                        <p><small>Note: Certain values are only available for specific line types.</small></p>
                    </div>
                )}
                <div className="sticky top-0 z-5 flex items-center justify-between border-b border-gray-300 bg-white px-2 py-2 dark:border-gray-300/30 dark:bg-background-primary-dark sm:px-2">
                    <div className="flex items-center gap-4">
                        <button
                            className={classNames(
                                "py-2 px-2",
                                mainTab === 0 ? "border-b-2 border-blue-500 font-bold" : "text-gray-700"
                            )}
                            onClick={() => setMainTab(0)}
                        >
                            Form
                        </button>
                        <button
                            className={classNames(
                                "py-2 px-2",
                                mainTab === 1 ? "border-b-2 border-blue-500 font-bold" : "text-gray-700"
                            )}
                            onClick={() => setMainTab(1)}
                        >
                            Lines
                        </button>
                        <button
                            className={classNames(
                                "py-2 px-2",
                                mainTab === 2 ? "border-b-2 border-blue-500 font-bold" : "text-gray-700"
                            )}
                            onClick={() => setMainTab(2)}
                        >
                            Survey Results
                        </button>
                    </div>
                    <div className="flex items-center gap-4">
                    <div className="relative">
                            <select
                                value={status}
                                onChange={(e) => setStatus(e.target.value)}
                                className="block w-full py-2 pl-3 pr-10 border border-gray-300 rounded-lg shadow-lg bg-white text-gray-700 focus:outline-none focus:ring-1 focus:ring-[#3b5aa9] transition ease-in-out duration-150 appearance-none"
                            >
                                {statusOptions.map(option => (
                                    <option key={option.label} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                            <svg className="absolute right-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-500 pointer-events-none" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                            </svg>
                    </div>
                        <Button
                            variant="primary"
                            className="w-24"
                            onClick={onSave}
                            isLoading={false}
                        >
                            Save
                        </Button>
                    </div>
                </div>
                {mainTab === 0 && (
                    <div className="p-2 sm:p-3">
                        <div className="relative rounded-lg bg-white p-3 dark:bg-background-primary-dark">
                            <form>
                                <dl className="grid grid-cols-12 gap-x-2 gap-y-4 sm:gap-x-4 sm:gap-y-8">
                                    {form &&
                                        Object.entries(form).map(([key, value]) => {
                                            if(key === 'line'){
                                                return <></>
                                            }
                                            return renderEditComponent(
                                                key,
                                                value,
                                                register,
                                                control,
                                                errors,
                                                dynamicOptions,
                                            );
                                        })}
                                </dl>
                            </form>
                        </div>
                    </div>
                )}
                {mainTab === 1 && (
                    <div className="p-2 sm:p-3">
                        <div className="relative rounded-lg bg-white p-3 dark:bg-background-primary-dark">
                            <form>
                                <dl className="grid grid-cols-12 gap-x-2 gap-y-1 sm:gap-x-4 sm:gap-y-8">
                                    {form &&
                                        Object.entries(form).map(([key, value]) => {
                                            if(key !== 'line'){
                                                return
                                            }
                                            return renderEditComponent(
                                                key,
                                                value,
                                                register,
                                                control,
                                                errors,
                                                dynamicOptions,
                                            );
                                        })}
                                </dl>
                            </form>
                        </div>
                    </div>
                )}
                {mainTab === 2 && (
                    <div className="p-4">
                        <div className="border-b border-gray-300 mb-6">
                            <nav className="flex space-x-4">
                                {data?.map((item, index) => (
                                    <button
                                        key={index}
                                        onClick={() => setActiveCardTab(index)}
                                        className={classNames(
                                            "py-2 px-4",
                                            activeCardTab === index ? "border-b-2 border-blue-500 font-bold" : "text-gray-700"
                                        )}
                                    >
                                        {item.title}
                                    </button>
                                ))}
                            </nav>
                        </div>
                        {data?.length > 0 && (
                    <div className="mb-6">
                        <div className="p-4 border border-gray-300 rounded-lg shadow-lg bg-white">
                            <div className="border-b border-gray-300">
                                <h3 className="text-lg font-semibold mb-4 text-gray-900">{data[activeCardTab].title}</h3>
                                <nav className="flex space-x-4">
                                    {data[activeCardTab].submission_data.map((_, tabIndex) => (
                                        <button
                                            key={tabIndex}
                                            onClick={() => setActiveLineTab(tabIndex)}
                                            className={classNames(
                                                "py-2 px-4",
                                                activeLineTab === tabIndex ? "border-b-2 border-blue-500 font-bold" : "text-gray-700"
                                            )}
                                        >
                                            Line {tabIndex + 1}
                                        </button>
                                    ))}
                                </nav>
                            </div>
                            <div className="p-2">
                                {data[activeCardTab].submission_data[activeLineTab] && (
                                    <div>
                                        <h2 className="text-xl font-bold mb-4 text-gray-800">Line {activeLineTab + 1}</h2>
                                        <div className="space-y-4">
                                            {Object.keys(data[activeCardTab].form_schema || {}).map((key) => {
                                                const field = data[activeCardTab].form_schema[key];
                                                const value = data[activeCardTab].submission_data[activeLineTab][key] || data[activeCardTab].submission_data[activeLineTab][key.split('-')[1]];
                                                if(field?.type !== 'toggle' && !value){
                                                    return null
                                                }
                                                return (
                                                    <div key={key} className="flex flex-col">
                                                        <span className="font-bold text-gray-700">{field.label}</span>
                                                        <div className="mt-2">
                                                            {renderFieldValue(field, value)}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
                    </div>
                )}
            <ConfirmModal isOpen={showModal} onClose={() => setShowModal(!showModal)} onConfirm={() => submitForm(getValues())}/>
            </div>
    );
};

export default EditFormModal;