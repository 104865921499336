import React, { useState } from "react";
import Label from "../Label";
import { classNames } from "utils";
import { isBoolean } from "utils/helpers";
import FormInput from "../FormInput";

const ToggleWithConditional = ({
    name,
    label,
    register,
    error,
    value,
    options = {},
    ...rest
}) => {
    const [isChecked, setIsChecked] = useState(value?.value || value);
    const [text, setText] = useState("");
    const hasError = isBoolean(error) ? error : !!error;

    const handleToggleChange = () => {
        if (!options.readOnly) {
            setIsChecked(!isChecked);
        }
    };

    const handleTextChange = (e) => {
        setText(e.target.value);
    };
    let { conditional, control } = rest;
    console.log(conditional)
    return (
        <div className="flex flex-col mt-4">
            <div className="flex items-center">
                <label
                    htmlFor={`${name}-toggle`}
                    className={classNames(
                        "relative inline-flex items-center cursor-pointer",
                        options.readOnly && "opacity-60 cursor-not-allowed"
                    )}
                >
                    <input
                        id={`${name}-toggle`}
                        name={name}
                        type="checkbox"
                        checked={isChecked}
                        onClick={handleToggleChange}
                        value={isChecked}
                        disabled={options.readOnly}
                        {...register(name, options)}
                        {...rest}
                        className="sr-only"
                    />
                    <div
                        className={classNames(
                            "w-12 h-6 rounded-full transition-colors duration-200 ease-in-out",
                            isChecked ? "bg-green-500" : "bg-gray-300"
                        )}
                    >
                        <div
                            className={classNames(
                                "w-6 h-6 bg-white rounded-full shadow-md transform transition-transform duration-200 ease-in-out",
                                isChecked ? "translate-x-6" : "translate-x-0"
                            )}
                        />
                    </div>
                </label>

                {label && (
                    <div className="ml-3 text-sm">
                        <Label
                            name={`${name}-toggle`}
                            label={label}
                            error={hasError}
                            options={options}
                        />
                    </div>
                )}
            </div>

            {isChecked && Object.keys(conditional)?.length && (
              Object.entries(conditional).map(([key, value], idx) => {
                return (
                  <div key={`${name}-${key}`} className="mt-4">
                    <FormInput
                      control={control}
                      register={register}
                      name={key}
                      type={value?.type}
                      label={value?.label}
                      options={value?.options}
                      values={value?.values}
                    />
                  </div>
                )
              })
            )}

        </div>
    );
};

export default ToggleWithConditional;
