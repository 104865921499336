import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Start from "./start";
import CheckList from "./checklist";
import EndStep from "./end";
import Lines from "./lines";
import Spinner from "./surveySpinner";
import { getSurveyForms, getAllFormStatuses, getFormSubmissions, saveSurveyForm, postFrontendErrorLogs } from "api";
import SpeedTestTask from "./speedTestTask";
import useSockette from "hooks/useNewWebSocket";

const formMapping = {
  "Survey - Backboard Info": 2,
  "Survey - FireAlarm Task": 3,
  "Survey - SecurityAlarm Task": 4,
  "Survey - Elevator Task": 5,
  "Survey - Page Task": 6,
  "Survey - Fax Task": 7,
  "Survey - Modem Task": 8,
  "Survey - Voice Task": 9,
  "Survey - SecureEntry Task": 10,
  "Survey - Network Info Task": 11,
  "Survey - Lines Audit": 12,
  "Speed Test": 13,
};

const steps = [
  "start",
  "checklist",
  "backboardInfoTask",
  "fireAlarmTask",
  "securityAlarmTask",
  "elevatorTask",
  "pageTask",
  "faxTask",
  "modemTask",
  "voiceTask",
  "secureEntry",
  "networkInfoTask",
  "linesAuditTask",
  "speedTestTask"
];

const Survey = () => {
  const location = useLocation();
  
  const [instanceData, setInstanceData] = useState(location?.state?.data);
  const [currentStep, setCurrentStep] = useState(parseInt(localStorage.getItem('currentStep'), 10) || 0);
  const [forms, setForms] = useState([]);
  const [formStatues, setFormStatues] = useState({});
  const [loading, setLoading] = useState(true);
  const [code, setCode] = useState(null);
  const [isFinished, setIsFinished] = useState(null); 
  
  let {data, error, isConnected, sendMessage} = useSockette(`${process.env.REACT_APP_BACKEND_BASE_URL}/v3/real-time?ticket=${instanceData?.id}`);

  function getForm(formName) {
    return forms.find((record) => record.name === formName);
  }

  function readFileAsDataURL(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = () => reject(reader.error);
      reader.readAsDataURL(file);
    });
  }

  async function saveForm(formData, formId) {
    for(let i=0; i < formData?.length; i++){
      for (let [key, value] of Object.entries(formData[i])) {
        if (key.toLowerCase().includes('image') && value && value[0] instanceof File) {
          formData[i][key] = await readFileAsDataURL(value[0]);
        }
      }
    }

    try {
      const {data} = await saveSurveyForm(instanceData?.id, { formData, formId })
      if(isConnected){
        sendMessage({action: "mutation"})
      }

      await getFormStatues()
      return data
      console.log('Success:', response.data);
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
    }
  }

  async function getSubmittedForm(formId) {
    try {
      const response = await getFormSubmissions(formId, instanceData?.id)

      return response.data;
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
    }
  }

  async function getFormStatues() {
    try {
      const { data } = await getAllFormStatuses(instanceData?.id);

      if(!Object.keys(data).length){
        return
      }

      let {data: formsStatuses, code, isFinished} = data;
      setFormStatues(formsStatuses);
      setCode(code);
      setIsFinished(isFinished);
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
    }
  }

  function validTasks() {
    let tasks = [];

    forms.forEach((form) => {
      if (formMapping[form?.name] && !instanceData?.omitted_lines[form?.name]) {
        tasks.push({
          title: form?.title,
          status: formStatues[form?.id] || 'In Progress',
          stepId: formMapping[form?.name]
        });
      }
    });

    tasks.sort((a, b) => a.stepId - b.stepId);
    return tasks;
  }

  async function getAllSurveyForms() {
    try {
      let { data } = await getSurveyForms();

      if (data?.error) {
        console.error(`Error while getting survey forms: ${data?.error}`);
        return
      }

      setForms(data);
      setLoading(false);
    } catch (error) {
      console.error(`Error while getting survey forms: ${error}`);
      setLoading(false);
    }
  }

  useEffect(() => {
    getAllSurveyForms();
  }, []);

  useEffect(() => {
    localStorage.setItem('currentStep', currentStep);
  }, [currentStep]);
  
  useEffect(() => {
    getFormStatues()
  }, [data])

  if(loading){
    return <Spinner size="large" />
  }

  const renderTask = () => {
    const formNames = {
      "backboardInfoTask": "Survey - Backboard Info",
      "fireAlarmTask": "Survey - FireAlarm Task",
      "securityAlarmTask": "Survey - SecurityAlarm Task",
      "elevatorTask": "Survey - Elevator Task",
      "pageTask": "Survey - Page Task",
      "faxTask": "Survey - Fax Task",
      "modemTask": "Survey - Modem Task",
      "voiceTask": "Survey - Voice Task",
      "secureEntry": "Survey - SecureEntry Task",
      "networkInfoTask": "Survey - Network Info Task",
      "linesAuditTask": "Survey - Lines Audit",
      "speedTestTask": "Speed Test"
    };

    const formName = formNames[steps[currentStep]];

    switch (steps[currentStep]) {
      case "start":
        return <Start setCurrentStep={setCurrentStep} currentStep={currentStep} form={getForm('Survey - Location Info')} instanceData={instanceData} saveForm={saveForm} />;
      case "checklist":
        return <CheckList setCurrentStep={setCurrentStep} currentStep={currentStep} tasks={validTasks()} specialInstructions={instanceData?.special_instructions} instanceId={instanceData?.id} code={code}/>;
      case "speedTestTask":
        return <SpeedTestTask setCurrentStep={setCurrentStep} state={instanceData?.ticket_data?.stateIdentifier} saveForm={saveForm} form={getForm('Speed Test')}/>;
      case "end":
        return <EndStep />;
      default:
        return formName ? (
          <Lines
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
            form={getForm(formName)}
            saveForm={saveForm}
            getSubmittedForm={getSubmittedForm}
            formTitle={formName}
          />
        ) : null;
    }
  };

  if(isFinished){
    return (<EndStep/>)
  }

  return (
    <>
      {renderTask()}
    </>
  );
};

export default Survey;
