import React, { useState } from "react";
import Label from "../Label";
import { classNames } from "utils";
import { isBoolean } from "utils/helpers";

const Toggle = ({
  name,
  label,
  register,
  error,
  value,
  options = {},
  ...rest
}) => {
  const [isChecked, setIsChecked] = useState(value?.value || value || false);
  const hasError = isBoolean(error) ? error : !!error;

  const handleChange = () => {
    if (!options.readOnly) {
      setIsChecked(!isChecked);
    }
  };

  return (
    <div className="flex items-center mt-4">
      <label
        htmlFor={`${name}-toggle`}
        className={classNames(
          "relative inline-flex items-center cursor-pointer",
          options.readOnly && "opacity-60 cursor-not-allowed"
        )}
      >
        <input
          id={`${name}-toggle`}
          name={name}
          type="checkbox"
          checked={isChecked}
          onClick={handleChange}
          value={isChecked}
          disabled={options.readOnly}
          {...register(name, options)}
          {...rest}
          className="sr-only"
        />
        <div
          className={classNames(
            "w-12 h-6 rounded-full transition-colors duration-200 ease-in-out",
            isChecked ? "bg-green-500" : "bg-gray-300"
          )}
        >
          <div
            className={classNames(
              "w-6 h-6 bg-white rounded-full shadow-md transform transition-transform duration-200 ease-in-out",
              isChecked ? "translate-x-6" : "translate-x-0"
            )}
          />
        </div>
      </label>

      {label && (
        <div className="ml-3 text-sm">
          <Label
            name={`${name}-toggle`}
            label={label}
            error={hasError}
            options={options}
          />
        </div>
      )}
    </div>
  );
};

export default Toggle;
