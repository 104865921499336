import React, { useState, useEffect } from "react";
import {
  useParams,
  useSearchParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import jwtDecode from "jwt-decode";
import withFlowState from "components/hoc/withFlowState";
import FormInput from "components/common/form/FormInput";
import Spinner from "components/common/Spinner";
import {
  getSlackChannels,
  getWorkflowInstances,
  startWorkflow,
  getWorkflow,
  createActivationTicket,
  canStartFlow,
  getTurnupToken,
  updateTurnUpUserInfo,
  sendOtp,
  verifyOtp,
  updateExistingTimer,
  processTicket
} from "api";
import { WORKFLOW_TRIGGER2 } from "utils/forms";
import ErrorDisplay from "components/common/Error";
import { useAuthState } from "contexts/auth";
import { FlowLayout } from "components/layouts";
import Flow from "./Flow";
import { formatWorkflow, formatWorkflowIfNodes } from "utils";
import { RequireFlowAuth } from "components/auth/RequireAuth";
import { NODE_TYPE } from "utils/constants";
import { Button, Modal } from "components/common";
import {
  InformationCircleIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";
import {
  getReachableNodes,
  searchNodesForReference,
} from "workflow-editor/utils/variable-references";
import VerifyForm from "./VerifyForm.js";
import ScreenShot from "components/common/ScreenShot/index.js";
import axios from "axios";
const FlowHome2 = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showInfo, setShowInfo] = useState(true);
  const [verifyError, setVerifyError] = useState(false);
  const [verifyCode, setVerifyCode] = useState(false);
  const [routeState] = useState(location?.state || {});
  const [otpData, setOtpData] = useState(null);
  const [agentInfo, setAgentInfo] = useState({});
  const [timeRemaining, setTimeRemaining] = useState(120);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [verifyForm, setVerifyForm] = useState(false);
  const [startFlow, setStartFlow] = useState(false);
  const [isInfoModalOpen, setInfoModalOpen] = useState(false);
  const [isSafari] = useState(false);

  const [iw_ticket, setIwTicketNumber] = useState('');

  const { updateAuthState } = useAuthState();



  const [params, setParams] = useSearchParams();

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      iwTicket: routeState?.iwTicket || "",
    },
  });

  const handleInfoModalPopup = () => {
    setInfoModalOpen(!isInfoModalOpen);
  };
  
  const handleWorkflowStart = (data) => {
    navigate(`/surveytest`, {
      state: {
        data
      }
    });
  };

  const fetchWorkflowInstance = async (iw_ticket, isRevisit, siteID) => {
    let args = {
      is_deleted: false,
      page: 0,
    }

    if(isRevisit){
      args['status__in'] =  ["Scheduled", "In Progress", "Escalated", "Failure"];
      args['account_number'] = siteID
    }else{
      args['status__in'] =  ["Scheduled", "In Progress", "Escalated"];
      args['iw_ticket'] = iw_ticket
    }

    const {
      data: { data = [] },
    } = await getWorkflowInstances(args);

    const sorted = data.sort((a, b) => {
      return new Date(b.created_at) - new Date(a.created_at);
    });

    if(isRevisit){
      let instance = sorted?.[0];
      instance['iw_ticket'] = iw_ticket;
      instance['form_data']['iw_ticket'] = iw_ticket;

      return instance
    }

    return sorted?.[0];
  };



  const onVerificationSubmit = async (inputData) => {
    setLoading(true);

    const { data } = await sendOtp({
      phoneNumber: inputData?.phoneNumber,
      iwTicket: inputData?.iwTicket,
      name: inputData?.firstName + "" + inputData?.lastName,
      email: inputData?.email,
      company: inputData?.company,
      type: "sms",
    });

    if (!data?.success) {
      setError(data?.text || "Unable to send verification Code");
      setLoading(false);

      return;
    }

    setOtpData(data?.data);
    setVerifyCode(true);

    setLoading(false);

    return;
  };

  const onSubmit = async (input) => {
    try {
      setIwTicketNumber(input.iwTicket);

      if(input?.firstName || input?.lastName){
        localStorage.setItem("chat_name", `${input?.firstName} ${input?.lastName}`)
      }

      if (!!showInfo) {
        setShowInfo(false);

        return;
      }
      setLoading(true);

      const { data: response } = await getTurnupToken(input.iwTicket);

      if (response?.data) {
        const { data: authData, success } = response;

        if (success && authData?.access_token) {
          await initializeAuth(authData.access_token, authData.user);
          setVerifyError(false);

          const { data } = await processTicket({
            iw_ticket: input?.iwTicket,
            phoneNumber: input?.phoneNumber,
            agentName: input?.firstName + " " + input?.lastName,
            company: input?.company,
          });
        
          handleWorkflowStart(data)
          setLoading(false);

          
          return;
        }
      }
      
      setError(response?.text || "Unable to verify ticket");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setVerifyError(true);
    }
  };

  const initializeAuth = async (access_token, user) => {
    if (!access_token) return;
    const existingToken = localStorage.getItem("access_token");
    let decoded = null;
    if (existingToken) {
      decoded = jwtDecode(existingToken);
    }
    if (!existingToken || (existingToken && decoded?.exp < Date.now() / 1000)) {
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("user", JSON.stringify(user));
      updateAuthState({
        status: "success",
        error: null,
        user,
      });
    }
  };

  if (loading) {
    <Spinner />;
  }

  return (

    <div className="flex flex-1 justify-center bg-background px-4 py-2 dark:bg-background-dark sm:py-12">
        <div className="flex w-full flex-col sm:w-2/3">
                    {loading && (
              <div className="absolute left-0 top-0 z-10 flex h-screen w-full items-center justify-center bg-background-primary bg-opacity-50 dark:bg-background-primary-dark">
                <Spinner />
              </div>
            )}
            <div className="py-4 text-center sm:block">
              <span className="block text-lg font-bold leading-8 tracking-tight text-header dark:text-header-dark sm:text-4xl sm:font-extrabold">
                {`Welcome to the EPIK Wizard!`}
              </span>
              <span className="leading-8 text-secondary-text dark:text-secondary-text-dark">{`This Wizard will guide you through either an EPIK Survey or EPIK Install depending on your Scope of Work.  Follow the instructions below and you will be guided to either the Survey Wizard or Install Wizard based on the ticket you received from Granite in your WorkMarket Scope of Work`}</span>
              <br />
            </div>
            <div className="flex-grow rounded-md bg-background-primary text-center shadow-md dark:bg-background-primary-dark sm:grow-0">
              <div className="relative p-8 pt-0">
                <form
                  className="space-y-4"
                  action="#"
                  method="POST"
                  onSubmit={handleSubmit(onSubmit)}
                  id="start"
                >
                  {showInfo && (
                    <div className="w-full  flex-row py-2">
                      <span className="font-bold leading-8 text-secondary-text dark:text-secondary-text-dark">
                        To begin, please follow these steps:{" "}
                      </span>
                      <div>
                        <ol className="list-inside list-decimal">
                          <li className="py-2 text-secondary-text dark:text-secondary-text-dark">
                            Enter in the Field Services Ticket Number provided
                            to you by Granite when you accepted this
                            installation job.
                          </li>
                          <li className="py-2 text-secondary-text dark:text-secondary-text-dark">
                            Provide your name for identification purposes.
                          </li>
                          <li className="py-2 text-secondary-text dark:text-secondary-text-dark">
                            Enter a valid phone number where we can send a
                            verification code.
                          </li>
                          <li className="py-2 text-secondary-text dark:text-secondary-text-dark">
                            Specify your company name (type N/A if you don't
                            know).
                          </li>
                          <li className="py-2 text-secondary-text dark:text-secondary-text-dark">
                            Click the "Send verification code" button
                          </li>
                          <li className="py-2 text-secondary-text dark:text-secondary-text-dark">
                            Enter the verification code sent to your phone in
                            the designated filed.
                          </li>
                          <li className="py-2 text-secondary-text dark:text-secondary-text-dark">
                            Click "Verify" button to start the flow
                          </li>
                        </ol>
                      </div>
                    </div>
                  )}
                  {!showInfo && (
                    <div className="flex flex-col items-center">
                      <div className="mt-1 w-full space-y-4 sm:w-1/2">
                        <span className="text-black dark:text-[#F9FAFB] sm:hidden">{`Please enter the provided ticket number.`}</span>
                        {error && <ErrorDisplay errors={[error]} />}
                        {Object.entries(WORKFLOW_TRIGGER2).map(
                          ([key, { type, label, options, values }]) => {
                            return (
                              <div className="mt-2" key={key}>
                                <FormInput
                                  control={control}
                                  register={register}
                                  name={key}
                                  type={type}
                                  label={label}
                                  options={options}
                                  values={values}
                                  error={!!errors?.[key]}
                                />
                              </div>
                            );
                          },
                        )}
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
            <div className="overflow-hidden rounded-b-md py-5 sm:py-8">
              {/* mobile */}
              <div className="m-2 flex justify-around text-secondary-text dark:text-secondary-text-dark sm:hidden">
                <Button
                  variant="secondary"
                  className="!dark:border-blue-600 cursor-default rounded-full !border-blue-600 bg-transparent !p-4"
                  size="small"
                  onClick={() => setShowInfo(true)}
                >
                  <InformationCircleIcon
                    className="block h-5 w-5 stroke-blue-500"
                    aria-hidden="true"
                  />
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  className="ml-5 flex-grow cursor-default !rounded-full !bg-[#3B82F6] text-white disabled:!bg-transparent disabled:text-[#9ca3af] dark:bg-transparent dark:text-white dark:disabled:text-[#9ca3af]"
                  onSubmit={onSubmit}
                  isLoading={loading}
                  form="start"
                  disabled={false}
                >
                  <span>
                    {showInfo
                      ? "Begin"
                      : verifyCode
                      ? "Verify Code"
                      : "Send Verification Code"}
                  </span>
                </Button>
              </div>
              {/* desktop */}
              <div className="hidden items-center text-secondary-text dark:text-secondary-text-dark sm:flex">
                <Button
                  type="submit"
                  variant="primary"
                  className="p-2"
                  onSubmit={onSubmit}
                  isLoading={loading}
                  form="start"
                  disabled={false}
                >
                  Start
                </Button>
              </div>
            </div>
            {verifyError && (
              <div className="space-y-2 text-red-500 dark:text-red-600">
                {`An error has occurred preventing the start of the install process.  
              Please standby while this issue is being investigated.  
              A Granite technician has been notified of the issue and will address the issue as soon as possible.  Please standby.`}
              </div>
            )}
        </div>
        </div>
  );
};

export default FlowHome2;
