import React, { useState } from "react";
import { useForm } from "react-hook-form";
import FormInput from "components/common/form/FormInput";
import Modal from "./helpModal";

const Start = ({ setCurrentStep, currentStep, form, instanceData }) => {
    const [showModal, setShowModal] = useState(false);

    const [formLookup, setFormLookup] = useState({
        "customerName": instanceData?.customer_name,
        "siteName": instanceData?.site_name,
        "ticketNumber": instanceData?.ticket_id,
        "city": instanceData?.ticket_data?.city,
        "state": instanceData?.ticket_data?.stateIdentifier
    });

    const { control, register, handleSubmit, watch } = useForm();

    return (
        <div className="min-h-screen bg-[#e6f0ff] font-poppins">
            <div className="sticky top-0 z-10 flex items-center gap-5 border-b border-gray-300 bg-white px-4 py-5 shadow-md sm:px-7">
                <div className="flex-1"></div>
                <div className="flex items-center gap-4 font-poppins">
                <button
                    onClick={() => setShowModal(true)}
                    className="mr-2 w-24 bg-[#3b5aa9] text-white px-6 py-2 rounded-lg border-[#3b5aa9] border-b-[4px] hover:brightness-110 hover:-translate-y-[1px] hover:border-b-[6px] active:border-b-[2px] active:brightness-90 active:translate-y-[2px] transition-all"
                >
                    Get Help
                </button>
                <button
                    onClick={() => setCurrentStep(currentStep + 1)}
                    className="text-[#3b5aa9] font-bold hover:text-[#2a4275] transition-colors duration-300"
                >
                    Save & Verify
                </button>
                </div>
            </div>
            <div className="flex flex-col items-center justify-center p-4">
                <h1 className="text-2xl font-bold text-[#3b5aa9] mb-4">Survey Info</h1>
                <form className="grid w-full max-w-md gap-4 bg-white p-6 rounded-lg shadow-md">
                    {form?.form_schema && Object.entries(form?.form_schema).map(
                        ([key, { type, label, options, values }]) => (
                            <div className="mt-2" key={key}>
                                <FormInput
                                    control={control}
                                    register={register}
                                    name={key}
                                    type={type}
                                    label={label}
                                    options={options}
                                    value={formLookup[key] || values}
                                    className="border-gray-300 focus:border-[#3b5aa9] focus:ring-[#3b5aa9]"
                                />
                            </div>
                        )
                    )}
                    <h1 className="text-2xl font-bold text-[#3b5aa9] mt-6 mb-4">Site Info</h1>
                    <FormInput
                        control={control}
                        register={register}
                        name="siteContact"
                        type="text"
                        label="Local Site Contact"
                        className="border-gray-300 focus:border-[#3b5aa9] focus:ring-[#3b5aa9]"
                    />
                </form>
            </div>
            <Modal isOpen={showModal} onClose={() => setShowModal(false)} />
        </div>
    );
};

export default Start;
