import axios from "axios";
import jwtDecode from "jwt-decode";
import { formatErrorMessage } from "utils";

const axiosClient = axios.create({
  headers: { "Content-Type": "application/json" },
  baseURL: "https://wizard.epik.io"
});

axiosClient.interceptors.response.use(
  (response) => response,
  (err) => {
    if (!err.response) return Promise.reject(err);
    if (err.response.status === 403) {
      return (window.location = err.response.status);
    }
    if (err.response.status === 401) {
      //logout
      localStorage.removeItem("access_token");
      localStorage.removeItem("user");
      return (window.location = "/login");
    }

    return Promise.reject(err);
  },
);

const onRequest = async (config) => {
  let token = getToken();
  if (token) {
    token = await checkIfTokenExpiredAndRefresh(token);
  }
  config.headers["Authorization"] = `Bearer ${token}`;
  return config;
};

const onRequestError = (error) => {
  return Promise.reject(error);
};

axiosClient.interceptors.request.use(onRequest, onRequestError);

export const getToken = () => {
  return localStorage.getItem("access_token");
};

export const checkIfTokenExpiredAndRefresh = async (token) => {
  const decoded = jwtDecode(token);
  if (decoded.exp < Date.now() / 1000 && decoded.rf_exp > Date.now() / 1000) {
    try {
      const newAxiosClient = axios.create({
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const { data } = await newAxiosClient.get("/api/auth/refresh");
      localStorage.setItem("access_token", data.data.access_token);
      return data.data.access_token;
    } catch (error) {
      return token;
    }
  }

  return token;
};

const secureClient = {
  get: (url, options = {}) => axiosClient.get(url, options),
  post: (url, data, options = {}) => axiosClient.post(url, data, options),
  put: (url, data, options = {}) => axiosClient.put(url, data, options),
  delete: (url, options = {}) => axiosClient.delete(url, options),
};

const client = {
  get: (url, options = {}) => axiosClient.get(url, { ...options }),
  post: (url, data, options = {}) =>
    axiosClient.post(url, data, { ...options }),
  put: (url, data, options = {}) => axiosClient.put(url, data, { ...options }),
  delete: (url, options = {}) => axiosClient.delete(url, { ...options }),
};

//smartsheets
// export const searchSmartSheet = (id, column, query) =>
//   client.get(`/api/smartsheets/${id}/search?column=${column}&query=${query}`);

// export const getSmartSheetColumns = (id) =>
//   client.get(`/api/smartsheets/${id}/columns`);

// export const getDatabaseEntryByZip = (zip) =>
//   secureClient.get(`/database/get-entry/${zip}`);
// export const setDatabaseEntry = (data) =>
//   secureClient.post(`/database/set-entry`, data);
//auth
export const userLogin = (data) => client.post(`/api/auth/login`, data);
export const userSignup = (data) => client.post(`/api/auth/signup`, data);
export const refreshUserToken = () => secureClient.get(`/api/auth/refresh`);
export const sendResetEmail = (data) =>
  client.post(`/api/auth/sendResetEmail`, data);
export const resetPassword = (data) =>
  client.post(`/api/auth/resetPassword`, data);
export const getTurnupToken = (iwTicket) =>  client.get(`/api/auth/turnup/${iwTicket}`)
export const sendOtp = (body) =>  client.post(`/api/auth/send_otp`, body)
export const verifyOtp = (body) =>  client.post(`/api/auth/verify_otp`, body)
export const getChatLink = (body) =>  client.post(`/api/auth/chat_link`, body)

//Connectwide
export const createActivationTicket = (iwTicket, body) => secureClient.post(`/api/connectwise/create/activation/${iwTicket}`, body);
export const checkActivationTicketStatus = (activationTicket) => secureClient.get(`/api/connectwise/status/activation/${activationTicket}`);
export const updateActivationTicket = (data) => secureClient.post(`/api/connectwise/update/activation`, data);
export const postNotes = (activationTicket, data) => secureClient.post(`/api/connectwise/summary/activation/${activationTicket}`, data);
export const getCurrentTimer = (data) => secureClient.post(`/api/connectwise/timer/activation/status`, data);
export const updateExistingTimer = (data) => secureClient.post(`/api/connectwise/timer/activation/update`, data);
export const getLatestNote = (activationTicket) => secureClient.get(`/api/connectwise/notes/activation/${activationTicket}`);
export const sendEscalationMessage = (workflowInstanceID) => secureClient.get(`/api/connectwise/escalation-message/send/${workflowInstanceID}`);
export const sendAutoMessage = (workflowInstanceID) => secureClient.get(`/api/connectwise/auto-message/send/${workflowInstanceID}`);
export const updateTurnUpUserInfo = (iwTicket, payload) => secureClient.post(`/api/connectwise/update/userInfo/${iwTicket}`, payload);
export const sendFlowState = (payload) => secureClient.post(`/api/connectwise/flow-state`, payload);
export const downloadReport = (filterPayload) => secureClient.get(`/api/connectwise/report?filter=${filterPayload}`, {responseType: 'blob'});

//v1 
export const sendScreenShot = (workflowInstanceId, payload) => secureClient.post(`/v1/screen-shots/${workflowInstanceId}`, payload);
export const getScreenShots = (workflowInstanceId) => secureClient.get(`/v1/screen-shots/${workflowInstanceId}`);
export const postEscalationV1 = (payload) => secureClient.post(`/v1/escalation`, payload);
export const postResolutionV1 = (escalationId, payload) => secureClient.post(`/v1/resolution/${escalationId}`, payload);
export const updateWorkflowInstanceV1 = (wid, payload) => secureClient.post(`/v1/workflow-instance/${wid}/update`, payload);
export const updateEscalationV1 = (escalationId, status) => secureClient.get(`/v1/update-escalation/${escalationId}?status=${status}`);
export const getProvisionStatus = (serialNumber) => secureClient.get(`/api/epik/status-provision/${serialNumber}`);
export const getWorkflowTimeStamp = (wid) => secureClient.get(`/v1/timer/${wid}`);

//v2
export const getRecentFlows = () => secureClient.get(`/v2/api/instances`);
export const getRecentConv = () => secureClient.get(`/v2/api/epikchats`);
export const getRecentConvMessages = (id) => secureClient.get(`/v2/api/epikchats/${id}`);

//v3
export const processTicket = (body) => secureClient.post(`/v3/api/cw/process`, body);
export const getSurveyForms = () => secureClient.get(`/v3/api/survey/forms`);
export const getSurveyFormsPm = () => secureClient.get(`/v3/api/survey/pm/form`);
export const getAllFormsTc = (instanceId) => secureClient.get(`/v3/api/survey/all-forms/${instanceId}`);
export const getSurveyCode = (instanceId) => secureClient.get(`/v3/api/survey/generate-code/${instanceId}`);
export const confirmCode = (instanceId) => secureClient.get(`/v3/api/survey/update/status/${instanceId}`);
export const updateStatusTc = (instanceId, body) => secureClient.put(`/v3/api/survey/status/update/${instanceId}`, body);

export const updateSurveyCW = (instanceId) => secureClient.get(`/v3/api/cw/update/${instanceId}`);
export const createSurveyTicket = (instanceId) => secureClient.get(`/v3/api/cw/create/ticket/${instanceId}`);
export const getSubmittedFormPm = (instanceId) => secureClient.get(`/v3/api/survey/pm/forms/${instanceId}`);

export const getAllFormStatuses = (instanceId) => secureClient.get(`/v3/api/survey/status/forms/${instanceId}`);
export const getFormSubmissions = (formId, instanceId) => secureClient.get(`/v3/api/survey/submission/form/${formId}/instance/${instanceId}`);
export const saveSurveyForm = (instanceId, body) => secureClient.post(`/v3/api/survey/submit/${instanceId}`, body);
export const savePmForm = (instanceId, body) => secureClient.post(`/v3/api/survey/pm/submit/${instanceId}`, body);

export const checkTicketType = (ticket) => secureClient.get(`v3/api/cw/check/ticket?ticket=${ticket}`);

//Adva
export const getConnectorIp = (uid) => secureClient.get(`/advaip/${uid}`);

//chat
export const getChatMessages = (workflowInstanceId, wait=0) => secureClient.get(`/api/chat/get_messages/${workflowInstanceId}?wait=${wait || 0}`);
export const addNewMessage = (payload) => secureClient.post(`/api/chat/add_message`, payload);
export const createChat = (payload) => secureClient.post(`/api/chat/create_chat`, payload);
export const sendSS = (payload) => secureClient.post(`/api/chat/screen_shot`, payload);
export const getSS = (workflowInstanceId) => secureClient.get(`/api/chat/screen_shot/${workflowInstanceId}`);
export const getCurrentFlowState = (workflowInstanceId) => secureClient.get(`/api/chat/instance_data/${workflowInstanceId}`);

//Slack
export const postAlertToSlack = (data) =>
  secureClient.post(`/api/notifications/alerts`, data);
export const postToSlack = (data) =>
  secureClient.post(`/api/notifications`, data);
export const getSlackChannels = () =>
  secureClient.get(`/api/notifications/channels`);

//turn up summaries
export const postTurnUpSummary = (data) =>
  secureClient.post(`/api/notifications/turnupsummary`, data);

//backend
export const pingWAN = (ip) => client.get(`/api/pingwanip/${ip}`);

export const getApiEndpoint = (_endpoint, params = {}, args = []) => {
  let endpoint = "/api/" + _endpoint;
  // replace parameter keys with their values
  Object.entries(params ?? {}).forEach(([key, value]) => {
    endpoint = endpoint.replace(`<${key}>`, value);
  });

  // set query parameters
  const [ep, query] = endpoint.split("?");
  endpoint = ep;
  const search = new URLSearchParams(query);
  args.forEach(({ key, value }) => search.set(key, value));
  if (search.toString()) endpoint += "?" + search.toString();

  return client.get(endpoint);
};

//workflows
export const getWorkflow = (id) => secureClient.get(`/api/workflows/${id}`);
export const getWorkflows = (args) =>
  secureClient.get(constructUrl(`/api/workflows`, args));
export const createWorkflow = (data) =>
  secureClient.post(`/api/workflows`, data);
export const updateWorkflow = (id, data) =>
  secureClient.post(`/api/workflows/${id}`, data);
export const deleteWorkflow = (id) =>
  secureClient.delete(`/api/workflows/${id}`);
export const startWorkflow = (data) =>
  client.post(`/api/workflows/start`, data);

//workflows instances
export const getWorkflowInstance = (id) =>
  secureClient.get(`/api/workflow_instances/${id}`);
export const getWorkflowInstances = (args) =>
  secureClient.get(constructUrl(`/api/workflow_instances`, args));

export const createWorkflowInstance = (data) =>
  secureClient.post(`/api/workflow_instances`, [data]);
export const createWorkflowInstances = (data) =>
  secureClient.post(`/api/workflow_instances`, data);
export const updateWorkflowInstance = (id, data) =>
  secureClient.post(`/api/workflow_instances/${id}`, data);
export const deleteWorkflowInstance = (id) =>
  secureClient.delete(`/api/workflow_instances/${id}`);

export const updateFlowInstance = (id, data) =>
  secureClient.post(`/api/flow/${id}`, data);

export const restartWorkflowInstance = (id) =>
  secureClient.post(`/api/workflow_instances/restart/${id}`, null);

//users
export const getCurrentUser = () => secureClient.get(`/api/users/me`);
export const getUser = (id) => secureClient.get(`/api/users/${id}`);
export const getUsers = (args) =>
  secureClient.get(constructUrl(`/api/users`, args));
export const getAllUsers = (args) =>
  secureClient.get(constructUrl(`/api/users/all`, args));
export const createUser = (data) => secureClient.post(`/api/users`, data);
export const updateUser = (id, data) =>
  secureClient.post(`/api/users/${id}`, data);
export const deleteUser = (id) => secureClient.delete(`/api/users/${id}`);
export const getTeammates = (id) =>
  secureClient.get(`/api/users/${id}/teammates`);
export const getTeammateOf = (id) =>
  secureClient.get(`/api/users/${id}/teammateof`);
export const addTeammate = (userId, teammateId, data) =>
  secureClient.post(`/api/users/${userId}/teammates/${teammateId}`, data);
export const deleteTeammate = (userId, teammateId) =>
  secureClient.delete(`/api/users/${userId}/teammates/${teammateId}`);

//user settings
export const getUserSetting = (id) =>
  secureClient.get(`/api/user_settings/${id}`);
export const getUserSettings = (args) =>
  secureClient.get(constructUrl(`/api/user_settings`, args));
export const createUserSetting = (data) =>
  secureClient.post(`/api/user_settings`, data);
export const updateUserSetting = (id, data) =>
  secureClient.post(`/api/user_settings/${id}`, data);
export const deleteUserSetting = (id) =>
  secureClient.delete(`/api/user_settings/${id}`);

//forms
export const getForm = (id) => secureClient.get(`/api/forms/${id}`);
export const getForms = (args) =>
  secureClient.get(constructUrl(`/api/forms`, args));
export const createForm = (data) => secureClient.post(`/api/forms`, data);
export const updateForm = (id, data) =>
  secureClient.post(`/api/forms/${id}`, data);
export const deleteForm = (id) => secureClient.delete(`/api/forms/${id}`);
export const cloneForm = (id) =>
  secureClient.post(`/api/forms/${id}/clone`, null);

//endpoints
export const getEndpoints = () => secureClient.get(`/api/endpoints`);

//epik
export const getBoxPorts = (serialNumber) => secureClient.get(`/api/epik/getboxPorts/${serialNumber}`);

// logs
export const postFrontendErrorLogs = (data) =>
  secureClient.post(`/api/logs/frontend_errors`, formatErrorMessage(data));

//files
export const uploadFiles = (data, onUploadProgress) =>
  secureClient.post(`/api/files/upload`, data, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    onUploadProgress,
  });

const constructUrl = (url, args = {}) => {
  if (Object.keys(args).length === 0) return url;

  let query = "";
  Object.keys(args).forEach((key) => {
    query += `${key}=${args[key]}&`;
  });
  return `${url}?${query.slice(0, query.length - 1)}`;
};
