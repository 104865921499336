import React from 'react';

function Modal({ isOpen, onClose}) {
    if (!isOpen) return null;
    return (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg shadow-lg p-6 max-w-sm w-full">
                <h2 className="text-xl font-semibold text-[#3b5aa9] mb-4">Need Help?</h2>
                <p className="text-gray-700">Call this number for help: <a href="tel:123456789" className="text-[#3b5aa9] font-semibold">833-479-4768</a></p>
                <button
                    onClick={onClose}
                    className="mt-4 bg-[#3b5aa9] text-white px-4 py-2 rounded-lg hover:bg-[#2a4579] focus:outline-none focus:ring-2 focus:ring-[#3b5aa9]"
                >
                    Close
                </button>
            </div>
        </div>
    );
}

export default Modal;
